<template>
    <b-container fluid>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <div class="card-title">Jobs</div>

                <div class="card-tools">
                    <button type="button" class="mr-1 btn btn-default" @click="loadJobs">
                        <i class="fas fa-sync"></i>
                    </button>
                    <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('jobs.create')">
                        <i class="fas fa-plus-circle"></i> Neuer Job
                    </button>
                    <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                </div>
            </div>

            <div class="card-body">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Bezeichnung</th>
                            <th>Untertitel</th>
                            <th>Aktiv</th>
                            <th>Aktion</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(job,index) in jobs" :key="job.id">
                            <td>{{ index +1 }}</td>
                            <td>{{ job.title }}</td>
                            <td>{{ job.subtitle }}</td>
                            <td>
                                <i v-if="job.active == 1" class="fas fa-check"></i>
                                <i v-else class="fas fa-times"></i>
                            </td>
                            <td>
                                <b-button size="sm" @click="editModal(job)" class="mr-1" variant="warning" v-if="$auth.check('jobs.edit')"><i class="fas fa-edit"></i></b-button>
                                <b-button size="sm" @click="deleteJob(job.id)" variant="danger" v-if="$auth.check('jobs.destroy')"><i class="fas fa-trash"></i></b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <b-modal id="jobModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="title" class="control-label">Titel*</label>
                        <input v-model="form.title" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('title')}">
                        <has-error :form="form" field="title"></has-error>
                    </div>
                </div>

                 <div class="col-md-4">
                    <div class="form-group">
                        <label for="subtitle" class="control-label">Untertitel</label>
                        <input v-model="form.subtitle" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('subtitle')}">
                        <has-error :form="form" field="subtitle"></has-error>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label for="title" class="control-label">Stellentyp*</label>
                        <input v-model="form.type" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('type')}">
                        <!-- <select v-model="form.type" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('type')}">
                            <option value="Vollzeit">Vollzeit</option>
                            <option value="Teilzeit">Teilzeit</option>
                            <option value="Aushilfe">Aushilfe</option>
                        </select> -->
                        <has-error :form="form" field="type"></has-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="active" class="control-label">Aktiv*</label>
                        <select v-model="form.active" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('active')}">
                            <option value="1">Ja</option>
                            <option value="0">Nein</option>
                        </select>
                        <has-error :form="form" field="active"></has-error>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="companies" class="control-label">Firmen*</label>
                        <select v-model="form.companies" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('companies')}" multiple>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{company.name}}</option>
                        </select>
                        <has-error :form="form" field="companies"></has-error>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label for="knowledge" class="control-label col-form-label col-form-label-sm">Das musst du mitbringen*</label>
                    <ckeditor :editor="editor" :config="editorConfig" v-model="form.knowledge" :class="{'is-invalid': form.errors.has('knowledge')}"></ckeditor>
                    <has-error :form="form" field="knowledge"></has-error>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label for="tasks" class="control-label col-form-label col-form-label-sm">Deine Aufgaben*</label>
                    <ckeditor :editor="editor" :config="editorConfig" v-model="form.tasks" :class="{'is-invalid': form.errors.has('tasks')}"></ckeditor>
                    <has-error :form="form" field="tasks"></has-error>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label for="offers" class="control-label col-form-label col-form-label-sm">Wir bieten dir*</label>
                    <ckeditor :editor="editor" :config="editorConfig" v-model="form.offers" :class="{'is-invalid': form.errors.has('offers')}"></ckeditor>
                    <has-error :form="form" field="offers"></has-error>
                </div>
            </div>
        </b-modal>



    </b-container>
</template>

<script>
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: "Settings-Jobs",
    title: "Jobs",

    data(){
        return {
            jobs: [],
            companies: [],
            editMode: false,
            modalTitle: '',
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            form: new window.Form({
                id: "",
                title: "",
                subtitle: "",
                type: "",
                knowledge: "",
                tasks: "",
                offers: "",
                active: "",
                companies: []
            }),
        }
    },

    methods:{
        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                //Edit User
                this.editJob(id);
            }
            else
            {
                //Create User
                this.createJob();
            }
        },

        createModal()
        {
            this.editMode = false;
            this.modalTitle = "Neuen Job anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("jobModal");
        },
        
        editModal(Job)
        {
            this.editMode = true;
            this.modalTitle = "Job editieren";
            this.form.clear();
            this.form.reset();
            this.form.fill(Job);
            this.$bvModal.show("jobModal");
        },

        createJob() {
            this.$Progress.start();
            this.form
                .post("/jobs")
                .then(() => {
                    this.$bvModal.hide("jobModal");
                    this.$swal({
                        icon: "success",
                        title: "Job wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadJobs();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        editJob(id){
            this.$Progress.start();
            this.form
                .put("/jobs/"+ id)
                .then(() => {
                    this.$bvModal.hide("jobModal");
                    this.$swal({
                        icon: "success",
                        title: "Job wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadJobs();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteJob(id){
            this.$swal({
                title: "Möchtest du den Job wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/jobs/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Job erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadJobs();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        loadJobs(){
            this.axios
                .get("/jobs")
                .then((response) => {
                    this.jobs = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadCompanies(){
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created(){
        this.loadJobs();
        this.loadCompanies();
    }

}
</script>

<style>

</style>